import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Image from "gatsby-image";

import Layout from "../components/Layout";
import { Box, Flex, Heading, Stack, Divider } from "@chakra-ui/core";
import { Section } from "../components/Section";
import { Button } from "../components/Button";
import { HTMLContent } from "../components/Content";
import { markdownToHtml } from "../utils/markdown";
import { Link } from "../components/Link";
import { Text } from "../components/Text";
import { DealsSection } from "../components/DealsSection";

export const DealsPageTemplate = ({ main, intro, deals, outro, contact }) => (
  <>
    <Section color="backgroundWhite">
      <Flex
        width={["xs", "sm", "md", "5xl"]}
        marginTop={["16", "26", "20", "20"]}
        marginBottom={["12", "12", "20", "26"]}
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box
          width="full"
          height="full"
          maxWidth={["3xs", "3xs", "2xs", "xs"]}
          rounded="0.5rem"
          overflow="hidden"
        >
          <Image fluid={main.image.childImageSharp.fluid} alt="" />
        </Box>
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems={["center", "center", "center", "flex-start"]}
          maxWidth={["full", "full", "md", "lg"]}
          marginTop={["6", "6", "6", "0"]}
          padding={[3, 3, 3, 3]}
          spacing={[5, 5, 8, 8]}
        >
          <Heading
            fontSize={["3xl", "4xl", "5xl", "5xl"]}
            fontWeight="light"
            color="brand.gray"
            textAlign={["center", "center", "center", "left"]}
            as="h1"
          >
            {main.title}
          </Heading>
          <Text
            maxWidth={["md", "md", "md", "md"]}
            fontSize={["md", "xl", "xl", "xl"]}
            fontWeight="light"
            textAlign={["center", "center", "center", "left"]}
            as="h2"
            whiteSpace="pre-wrap"
          >
            {main.description?.split("\\n").map((text) => (
              <p>{text.replace("\\n", "")}</p>
            ))}
          </Text>
          <Flex>
            {main.buttons &&
              main.buttons.map((button, i) => {
                return (
                  <Link key={i} to={button.url}>
                    <Button variant="dark" margin={3}>
                      {button.title}
                    </Button>
                  </Link>
                );
              })}
          </Flex>
        </Stack>
      </Flex>
    </Section>
    <Section color="backgroundGray">
      <Stack
        width={["full", "full", "2xl", "3xl"]}
        marginTop={["12", "12", "20", "20"]}
        marginBottom={["12", "12", "20", "20"]}
        paddingLeft={["5", "6", "0", "0"]}
        paddingRight={["5", "6", "0", "0"]}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing="10"
      >
        <Divider width={["10", "12", "15", "15"]} borderColor="brandGray" />
        <Heading
          as="h2"
          fontSize={["2xl", "3xl", "4xl", "4xl"]}
          fontWeight="light"
          color="brand.gray"
          textAlign={["center", "center", "center", "left"]}
          marginBottom="10"
        >
          {intro.title}
        </Heading>
        <Text
          as="div"
          fontWeight="light"
          textAlign={"center"}
          fontSize={["md", "md", "lg", "xl"]}
          lineHeight={["lg", "lg", "xl", "2xl"]}
        >
          <HTMLContent content={markdownToHtml(intro.description)} />
        </Text>
        <Flex flexWrap="wrap" justifyContent="center">
          {intro.buttons &&
            intro.buttons.map((button, i) => {
              return (
                <Link key={button.url + "-" + i} to={button.url}>
                  <Button key={i} variant="dark" margin={3}>
                    {button.title}
                  </Button>
                </Link>
              );
            })}
        </Flex>
      </Stack>
    </Section>

    <DealsSection deals={deals} />

    <Section color="backgroundGray">
      <Stack
        width={["full", "full", "2xl", "3xl"]}
        marginTop={["12", "12", "20", "20"]}
        marginBottom={["12", "12", "20", "20"]}
        paddingLeft={["5", "6", "0", "0"]}
        paddingRight={["5", "6", "0", "0"]}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing="10"
      >
        <Divider width={["10", "12", "15", "15"]} borderColor="brandGray" />
        <Heading
          as="h2"
          fontSize={["2xl", "3xl", "4xl", "4xl"]}
          fontWeight="light"
          color="brand.gray"
          textAlign={["center", "center", "center", "left"]}
          marginBottom="10"
        >
          {outro.title}
        </Heading>
        <Text
          as="div"
          fontWeight="light"
          textAlign={"center"}
          fontSize={["md", "md", "lg", "xl"]}
          lineHeight={["lg", "lg", "xl", "2xl"]}
        >
          <HTMLContent content={markdownToHtml(outro.description)} />
        </Text>
        <Flex flexWrap="wrap" justifyContent="center">
          {outro.buttons &&
            outro.buttons
              .filter((btn) => !btn.hidden)
              .map((button, i) => {
                return (
                  <Link key={button.url + "-" + i} to={button.url}>
                    <Button key={i} variant="dark" margin={3}>
                      {button.title}
                    </Button>
                  </Link>
                );
              })}
        </Flex>
      </Stack>
    </Section>

    <Section color="backgroundWhite">
      <Stack
        width={["full", "full", "2xl", "3xl"]}
        marginTop={["12", "12", "20", "20"]}
        marginBottom={["12", "12", "20", "20"]}
        paddingLeft={["5", "6", "0", "0"]}
        paddingRight={["5", "6", "0", "0"]}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing="10"
      >
        <Divider width={["10", "12", "15", "15"]} borderColor="brandGray" />
        <Heading
          as="h2"
          fontSize={["2xl", "3xl", "4xl", "4xl"]}
          fontWeight="light"
          color="brand.gray"
          textAlign={["center", "center", "center", "left"]}
          marginBottom="10"
        >
          {contact.title}
        </Heading>
        <Text
          as="div"
          fontWeight="light"
          textAlign={"center"}
          fontSize={["md", "md", "lg", "xl"]}
          lineHeight={["lg", "lg", "xl", "2xl"]}
        >
          <HTMLContent content={markdownToHtml(contact.description)} />
        </Text>
        <Flex flexWrap="wrap" justifyContent="center">
          {contact.buttons &&
            contact.buttons.map((button, i) => {
              return (
                <Link key={i} to={button.url}>
                  <Button key={i} variant="dark" margin={3}>
                    {button.title}
                  </Button>
                </Link>
              );
            })}
        </Flex>
      </Stack>
    </Section>
  </>
);

const DealsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { main, intro, deals, outro, contact } = frontmatter;

  return (
    <Layout
      noIndex={true}
      seoTitle={main.title}
      seoDescription=""
      hasSeo={false}
      hideFooter={true}
      noNavButton={true}
    >
      <DealsPageTemplate
        main={main}
        intro={intro}
        deals={deals}
        contact={contact}
        outro={outro}
      />
    </Layout>
  );
};

DealsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DealsPage;

export const pageQuery = graphql`
  query DealById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        template
        main {
          description
          title
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttons {
            title
            url
          }
        }
        intro {
          description
          title
          buttons {
            title
            url
          }
        }
        outro {
          description
          title
          buttons {
            title
            url
            hidden
          }
        }
        contact {
          description
          title
          buttons {
            title
            url
          }
        }
        deals {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          link
          button_label
        }
      }
    }
  }
`;
