import React from "react";
import { Stack, Flex, Heading, Box } from "@chakra-ui/core";
import Image from "gatsby-image";
import { Section } from "./Section";
import { Text } from "./Text";
import { Button } from "./Button";
import { Link } from "./Link";

const TextSide = ({ title, excerpt, post_button_label, path }) => {
  return (
    <Flex
      flex="1"
      minWidth={["full", "full", "full", "380px"]}
      maxWidth={["full", "full", "full", "380px"]}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-evenly"
      marginRight={[0, 0, 5, 5]}
      marginLeft={[0, 0, 5, 5]}
    >
      <Flex
        width="full"
        justifyContent={["center", "center", "center", "flex-start"]}
      >
        {/* <Divider
          width={["10", "12", "15", "15"]}
          borderColor="brandGray"
          marginBottom="10"
          textAlign="center"
        /> */}
        <Heading
          as="h3"
          fontSize={["2xl", "3xl", "3xl", "3xl"]}
          fontWeight="light"
          color="brand.gray"
          textAlign={["center", "center", "center", "left"]}
          marginBottom="6"
          marginTop={["8", "8", "6", "0"]}
        >
          {title}
        </Heading>
      </Flex>
      <Text
        fontWeight="light"
        textAlign={["center", "center", "center", "left"]}
        marginBottom="6"
        letterSpacing="1px"
      >
        {excerpt}
      </Text>
      {post_button_label && (
        <Flex
          width="full"
          justifyContent={["center", "center", "center", "flex-start"]}
        >
          <Link to={`${path}`}>
            <Button
              variant="light"
              _hover={{
                bg: "brandLightGray",
              }}
            >
              {post_button_label}
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};

const ImageSide = ({ image }) => {
  return (
    <Flex
      flex="1"
      minWidth={["full", "full", "full", "400px"]}
      maxWidth={["full", "full", "full", "400px"]}
      height="auto"
      justifyContent="center"
      alignItems="center"
      marginRight={[0, 0, 5, 5]}
      marginLeft={[0, 0, 5, 5]}
    >
      <Box width="full" overflow="hidden" rounded="0.5rem">
        <Image alt="" fluid={image.childImageSharp.fluid} />
      </Box>
    </Flex>
  );
};

const Post = ({ title, excerpt, image, path, align, post_button_label }) => {
  return (
    <Flex
      width="full"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      marginBottom="12"
    >
      <Flex
        flexDirection="row"
        width="full"
        justifyContent="space-between"
        flexWrap={align === "left" ? "wrap-reverse" : "wrap"}
      >
        {align === "left" ? (
          <>
            <TextSide
              title={title}
              excerpt={excerpt}
              path={path}
              post_button_label={post_button_label}
            />
            <ImageSide image={image} />
          </>
        ) : (
          <>
            <ImageSide image={image} />
            <TextSide
              title={title}
              excerpt={excerpt}
              path={path}
              post_button_label={post_button_label}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export const DealsSection = ({ deals }) => {
  return (
    <Section color="backgroundWhite">
      <Stack
        width={["full", "full", "2xl", "4xl"]}
        marginTop={["12", "12", "20", "20"]}
        marginBottom={["0", "0", "8", "8"]}
        paddingLeft={["5", "5", "5", "0"]}
        paddingRight={["5", "5", "5", "0"]}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        {deals.map((deal, i) => {
          console.log(deal);
          const { title, description, image, link, button_label } = deal;

          const align = i % 2 === 0 ? "left" : "right";
          return (
            <Post
              key={title + description}
              align={align}
              title={title}
              excerpt={description}
              image={image}
              path={link}
              post_button_label={button_label}
            />
          );
        })}
      </Stack>
    </Section>
  );
};
